import styled from "styled-components";

export const Header = styled.div`
  position: relative;
  width: 100%;
  height: 50vh; /* Full height */
  @media only screen and (min-width: 1024px) {
    height: 64vh;
  }
  overflow: hidden;

  .image-wrapper {

    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;

export const TextWrapper = styled.div`
  position: absolute;
  bottom: 100px; /* Distance from the bottom */
  left: 220px; /* Distance from the left */
  @media only screen and (max-width: 768px) {
    left: 20px;
  }
  color: white;
  padding: 1em;
  background: rgba(0, 0, 0, 0.4); /* Semi-transparent background for better text visibility */

  .headDropShadow {
    text-shadow: 4px 4px 5px black;
  }

  .custom-closet {
    background-color: rgba(118, 39, 56, 0.9);
    color: white;
    padding: 1px 3px;
    &:hover, &:focus {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

export const Container = styled.div`
  max-width: 100%;
  margin-left: 5%;
  padding: 55px 30px 55px 30px;

  @media only screen and (min-width: 1200px) {
    min-height: 680px;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    min-height: 375px;
    padding: 40px 15px 15px 30px;
    margin: auto;

    button {
      width: 100% !important;
    }
  }
`;
